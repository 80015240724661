import React, { useState, useEffect, useRef } from "react";
import { Formik, FormikProps, Form, Field } from "formik";
import * as Yup from "yup";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import camelcaseKeys from "camelcase-keys";
import Input from "../../components/input/input";
//import Select from "../../components/input/select";
import Button from "../../components/button/button";
//import useRentals from "../../hooks/use-rentals-list";
import {
  ContactWrapper,
  ContactColumns,
  PageImage,
  SliderImage,
  ImageWrapper,
  ContactDetails,
  ContactTopTitle,
  ContactDescription,
  ContactFormWrapper,
  InputGroup,
} from "./style";
import Options from "../../theme/body/options";

interface MyFormValues {
  name: string;
  email: string;
  message: string;
  subject: string;
}

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Lūdzu, ievadiet savu vārdu"),
  email: Yup.string()
    .email("Invalid email")
    .required("Lūdzu, ievadiet savu e-pastu"),
  /*  subject: Yup.string().required("Lūdzu. izvēlieties tēmu"), */
  /* rentalItem: Yup.string(), */
  /*  subject: Yup.object().required("Lūdzu. izvēlieties tēmu"), */
  message: Yup.string().required("Lūdzu. uzraksti ziņu"),
});

type ContactProps = {
  title: string;
  body?: any;
  state: any;
  image: any;
};

const getContactFormEndpoint = () => {
  return process.env.GATSBY_CONTACT_FORM_ENDPOINT || "";
};

const onSubmit = (values: MyFormValues, actions: any) => {
  fetch(getContactFormEndpoint(), {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(values),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success && data.success !== "true") {
        actions.setErrors(
          camelcaseKeys({ type: "server", message: "errorResponse" })
        );
      }
    })
    .catch((error) => {
      actions.setErrors(camelcaseKeys(error.errors));
    })
    .then(() => {
      actions.setSubmitting(false);
      actions.setStatus({ success: true });
      actions.resetForm();
    });
};

const Contact: React.FunctionComponent<ContactProps> = ({
  title,
  body,
  state,
  image,
}) => {
  //const [success, setSuccess] = useState(false);
  const initialSubject: string = "E-pasts no tīmekļa lapas";
  /* const initialSubject: string = state?.nomu ? "nomu" : ""; */
  /*  const initialRentalItem: string = state?.item ? state.item : ""; */
  /*   const rentals: any[] = useRentals(); */
  /* const refTimer = useRef<number | null>(null);
const showtThanks = () => {
  if (refTimer.current !== null) return;
  setSuccess(true);
  refTimer.current = window.setTimeout(() => {
    //setIsShown(true);
    setSuccess(false);
  }, 5000);
}; */

  return (
    <ContactWrapper>
      <PageImage>
        <ImageWrapper>
          <SliderImage
            src={image}
            alt="post preview"
            backgroundColor="transparent"
          />
        </ImageWrapper>
      </PageImage>
      <ContactColumns>
        <ContactDetails className="contact_det">
          <ContactTopTitle>{title}</ContactTopTitle>
          <ContactDescription className="contact_des">
            {body && renderRichText(body, Options)}
          </ContactDescription>
        </ContactDetails>
        <ContactFormWrapper className="contact_form">
          <h2>Sazinies ar mums</h2>
          <Formik
            initialValues={{
              name: "",
              email: "",
              message: "",
              subject: initialSubject,
              /*        rentalItem: initialRentalItem, */
            }}
            onSubmit={onSubmit}
            validationSchema={SignupSchema}
          >
            {({
              handleChange,
              values,
              errors,
              handleBlur,
              touched,
              isSubmitting,
              status,
            }: FormikProps<MyFormValues>) => {
              const [success, setSuccess] = useState(false);
              const refTimer = useRef<number | null>(null);
              useEffect(() => {
                if (!status) return;
                setSuccess(true);
                refTimer.current = window.setTimeout(() => {
                  setSuccess(false);
                }, 3000);
              }, [status]);

              useEffect(() => {
                return () => {
                  if (refTimer.current !== null) {
                    window.clearTimeout(refTimer.current);
                  }
                };
              }, []);

              return (
                <>
                  <Form>
                    <InputGroup>
                      <Input
                        type="text"
                        name="name"
                        value={`${values.name}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Jūsu vārds"
                        disabled={isSubmitting}
                        notification={`${
                          errors.name && touched.name
                            ? errors.name
                            : ""
                        }`}
                      />
                    </InputGroup>
                    <InputGroup>
                      <Input
                        type="email"
                        name="email"
                        value={`${values.email}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Jūsu e-pasts"
                        disabled={isSubmitting}
                        notification={`${
                          errors.email && touched.email ? errors.email : ""
                        }`}
                      />
                    </InputGroup>
                    <Input
                      type="textarea"
                      name="message"
                      value={`${values.message}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Jūsu zinja"
                      disabled={isSubmitting}
                      notification={
                        errors.message && touched.message ? errors.message : ""
                      }
                    />
                    <Button
                      title={success ? "PALDIES" : "NOSŪTĪT"}
                      type="submit"
                      isLoading={isSubmitting ? true : false}
                      isSendt={false}
                      disabled={isSubmitting}
                      loader="NOSŪTĪŠANA.."
                      thankyou="PALDIES"
                    />
                  </Form>
                </>
              );
            }}
          </Formik>
        </ContactFormWrapper>
      </ContactColumns>
    </ContactWrapper>
  );
};

export default Contact;
