import React from 'react'
import * as Styled from './styled'

interface VideoEmbedProps {
    url: string;
    title: string;
    
  }

const VideoEmbed: React.FunctionComponent<VideoEmbedProps> = ({ url, title }) => {
    return (
        <Styled.VideoWrapper>
            <Styled.VideoEmbed>
            <iframe
                    src={url}
                    height="100%"
                    width="100%"
                    frameBorder="0"
                    scrolling="no"
                    title={title}
                    allowFullScreen={true}
                />
            </Styled.VideoEmbed>
        </Styled.VideoWrapper>
    )
}



export default VideoEmbed
