import styled from 'styled-components';

export const VideoWrapper = styled.div`
background-color: rgba(153,153,153,1);
margin-top: 1rem;
margin-bottom: 1rem;

`
export const VideoEmbed = styled.div`

position: relative;
padding-bottom: 56%;
    iframe {
       position: absolute;
       width: 100%;
       height: 100%;
    }
`
