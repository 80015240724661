import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import GatsbyImage from '../../components/gatsby-image';



export const ContactWrapper = styled.div`
`

export const PageImage = styled.div`

 position: relative;
 width: 100%;
 padding-bottom: 40%;
 @media (max-width: 767px) {
  padding-bottom: 56.25%;
}
margin-bottom: 30px;

 
`;

export const SliderImage = styled(GatsbyImage)`
height: 100%;
width: 100%;
object-fit: cover;
img {
  border-radius: 3px;
}
`

export const ImageWrapper = styled.div`
position: absolute;
width: 100%;
height: 100%;
`

export const ContactColumns = styled.div`
  position: relative;
  @media (min-width: 769px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  .contact_det {
    margin-top: 0;

    @media (min-width: 769px) {
      width: calc(55% - 30px);
      margin-right: 60px;

      &:before {
        width: calc(80% - 60px);
      }
    }
  }
  .contact_form {
    @media (min-width: 769px) {
      width: calc(45% - 30px);
      margin: 0;
    }
    .post_des {
      margin-top: 60px;
    }
  }
`;
export const ContactPageTitle = styled.div`
  margin-bottom: 90px;
  @media (max-width: 990px) {
    margin-bottom: 60px;
  }

  p {
    color: ${themeGet("colors.lightTextColor", "#757575")};
  }

  h2 {
    font-size: 30px;
    font-weight: 700;
    color: ${themeGet("colors.textColor", "#292929")};
    line-height: 1.53;
    margin-bottom: 15px;
    @media (max-width: 990px) {
      font-size: 26px;
      margin-bottom: 12px;
    }
    @media (max-width: 575px) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
`;

export const ContactTitle = styled.h1`
  font-size: 40px;
  font-weight: 400;
  color: ${themeGet("colors.textColor", "#292929")};
  line-height: 1.55;
  margin-bottom: 10px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 36px;
    margin-bottom: 20px;
  }
`;

export const ContactTopTitle = styled(ContactTitle)`
  line-height: 0.75;
  margin-bottom: 40px;
`;

export const ContactSideTitle = styled(ContactTitle)`
  line-height: 0.7;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ContactDetails = styled.div``;

export const ContactDescription = styled.div`
  font-size: ${themeGet("fontSizes.4", "16")}px;

  .mermaid {
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }

  p {
    font-size: ${themeGet("fontSizes.4", "16")}px;
    color: ${themeGet("colors.textLightColor", "#5e5e5e")};
    margin-bottom: 2em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin-bottom: 0.75em;
  }

  h1 {
    font-size: 30px;
    margin-bottom: 0.75em;
  }

  h2 {
    font-size: 25px;
    margin-bottom: 0.75em;
  }

  h3 {
    font-size: 21px;
  }

  h4 {
    font-size: 17px;
  }

  h5 {
    font-size: 15px;
  }

  h6 {
    font-size: 13px;
  }

  ol,
  ul {
    list-style-type: none;
    margin-left: 0rem;
    margin-bottom: 2rem;
    line-height: 2;
  }

  li {
    margin-bottom: 0;

    p {
      margin-bottom: 0;
    }
  }

  blockquote {
    font-family: "Poppins", sans-serif;
    font-size: 21px;
    font-weight: 500;
    line-height: 2;
    margin: 60px 0;
    @media (max-width: 1200px) {
      margin: 50px 0;
      font-size: 19px;
    }
    @media (max-width: 575px) {
      margin: 40px 0;
      font-size: 15px;
    }
    &:before,
    &:after {
      content: "";
      width: 30px;
      height: 1px;
      display: block;
      background: #292929;
    }
    &:before {
      margin-bottom: 60px;
      @media (max-width: 1200px) {
        margin-bottom: 50px;
      }
      @media (max-width: 575px) {
        margin-bottom: 40px;
      }
    }
    &:after {
      margin-top: 60px;
      @media (max-width: 1200px) {
        margin-top: 50px;
      }
      @media (max-width: 575px) {
        margin-top: 40px;
      }
    }

    p {
      font-size: 21px;
      font-weight: 500;
      line-height: 2;
      margin-bottom: 23px;
      @media (max-width: 1200px) {
        font-size: 19px;
      }
      @media (max-width: 1200px) {
        font-size: 17px;
      }
      @media (max-width: 575px) {
        font-size: 15px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    h4 {
      font-size: 16px;
      margin: 0;
      font-family: "Fira Sans", sans-serif;
      font-weight: 400;
    }
  }

  a {
    font-weight: 500;
    transition: 0.15s ease-in-out;
    text-decoration:underline;
    color: ${themeGet("textLinkColor", "#909090")};
  }
`;

export const ContactFormWrapper = styled.div`
  position: relative;
  background: ${themeGet("colors.primary", "#CFB8A8")};
  padding: 20px;
  h2 {
    color: #fff;
    font-weight: 400;
    line-height: 1.55;
  }
  .button {
    margin-top: 20px;
    @media (max-width: 575px) {
      margin-top: 10px;
    }
  }
`;

export const InputGroup = styled.div`
  .field-wrapper {
    margin-bottom: 20px;
    @media (max-width: 575px) {
      max-width: 100%;
      margin-bottom: 10px;
    }
  }
`;
