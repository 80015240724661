import React from "react";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import Layout from "../containers/layout";
import SEO from "../components/seo";
import Contact from "../containers/contact";

type ContactPageProps = {};

const ContactPage: React.FunctionComponent<ContactPageProps> = ({
  ...props
}: any) => {
  const { data, location } = props;
  const { state } = location;
  const image = getSrc(data.page.image) || undefined;

  return (
    <Layout>
      <SEO title={data.page.title} image={image} />
      <Contact
        image={data.page.image.gatsbyImageData}
        state={state}
        title={data.page.title}
        body={data.page.body}
      />
    </Layout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query {
    page: contentfulPage(slug: { eq: "kontakti" }) {
      id
      title
      body {
        raw
      }
      image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
