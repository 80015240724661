import * as React from "react";
import { Link } from "gatsby";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import VideoEmbed from "../../components/video-embed";
const Bold: any = ({ children }) => <span className="bold">{children}</span>;
const Text: any = ({ children }) => <p className="align-center">{children}</p>;



const options: any = {
 renderMark: {
   [MARKS.BOLD]: (text: string) => <Bold>{text}</Bold>,
 },
 renderNode: {
  [INLINES.HYPERLINK]: (node, children) => {
    return <a target="_blank" rel="noopener noreferrer" href={node.data.uri}>{children}</a>
  },
   [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
   [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
     return (
       <>
         {node.data.target?.__typename === "ContentfulVideo" && (
           <VideoEmbed
             url={node.data.target.url}
             title={node.data.target.title}
           ></VideoEmbed>
         )}
       </>
     );
   },
 },
 renderText: (text:any) => text.split('\n').flatMap((text:any, i:number) => [i > 0 && <br/>, text])
};


export default options;